<template>
    <main>
        <div class="row">
        <div class="form-group col-lg-6">
            <label class="font-weight-bolder required">Prueba </label>
            <select class="form-control form-control-sm" v-model="prueba_rapida.prueba_id" :class="{'is-invalid':$v.prueba_rapida.prueba_id.$error && $v.prueba_rapida.prueba_id.$dirty}">
                <option  v-for="item in pruebas" :key="item.id" :value="item.id">{{item.name}}</option>
            </select>
        </div>
        <div class="form-group col-lg-6">
            <label class="font-weight-bolder required">Fecha </label>
            <input class="form-control form-control-sm" type='date' v-model="prueba_rapida.fecha"
            :class="{'is-invalid':$v.prueba_rapida.fecha.$error && $v.prueba_rapida.fecha.$dirty}">
        </div>
        <div class="form-group col-lg-4">
            <label class="font-weight-bolder required">Casa comercial </label>
            <input class="form-control form-control-sm" type="text" v-model="prueba_rapida.casa_comercial"
            :class="{'is-invalid':$v.prueba_rapida.casa_comercial.$error && $v.prueba_rapida.casa_comercial.$dirty}">
        </div>
        <div class="form-group col-lg-4">
            <label class="font-weight-bolder required">Lote </label>
            <input class="form-control form-control-sm" type="text" v-model="prueba_rapida.lote"
            :class="{'is-invalid':$v.prueba_rapida.lote.$error && $v.prueba_rapida.lote.$dirty}">
        </div>
        <div class="form-group col-lg-4">
            <label class="font-weight-bolder required">Fecha Vencimiento </label>
            <input class="form-control form-control-sm" type="date" v-model="prueba_rapida.fecha_vencimiento"
            :class="{'is-invalid':$v.prueba_rapida.fecha_vencimiento.$error && $v.prueba_rapida.fecha_vencimiento.$dirty}">
        </div>
        <div class="form-group col-lg-4">
            <label class="font-weight-bolder required">Tipo de prueba </label>
            <select class="form-control form-control-sm" v-model="prueba_rapida.tipo_prueba"
            :class="{'is-invalid':$v.prueba_rapida.tipo_prueba.$error && $v.prueba_rapida.tipo_prueba.$dirty}">
                <option value="antigenos">Antigenos</option>
                <option value="anticuerpos">Anticuerpos</option>
            </select>
        </div>
        <div class="form-group col-lg-4">
            <label class="font-weight-bolder required">Trimestre </label>
            <select class="form-control form-control-sm" v-model="prueba_rapida.trimestre"
            :class="{'is-invalid':$v.prueba_rapida.trimestre.$error && $v.prueba_rapida.trimestre.$dirty}">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
            </select>
        </div>
        <div class="form-group col-lg-6">
            <label class="font-weight-bolder required">Resultado </label>
            <textarea class="form-control form-control-sm" v-model="prueba_rapida.resultado"
            :class="{'is-invalid':$v.prueba_rapida.resultado.$error && $v.prueba_rapida.resultado.$dirty}"></textarea>
        </div>
        <div class="form-group col-lg-6">
            <label class="font-weight-bolder">Observaciones</label>
            <textarea class="form-control form-control-sm" v-model="prueba_rapida.observaciones"></textarea>
        </div>
       
    </div>
     <div class="d-flex justify-content-end">
            <button class="btn btn-success btn-sm" @click="save"><i class="fa fa-save fa-fw"></i> Guardar</button>
        </div>
    </main>
</template>
<script>

import pruebasRapidasService from '@/services/pruebasRapidasService';
import Toast from "../../../../components/common/utilities/toast";
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import Swal from 'sweetalert2';
import pruebasRapidasUsuarioService from '../../../../services/pruebasRapidasUsuarioService';
import { required } from 'vuelidate/lib/validators';
export default{
    props:['usuario','eps'],
    data (){
        return {
            pruebas:[],
            prueba_rapida:{
                fecha:'',
                prueba_id:'',
                trimestre:'',
                resultado:'',
                observaciones:'',
                casa_comercial:'',
                lote:'',
                fecha_vencimiento:'',
                tipo_prueba:''
            }
        }
    },
    validations(){
        return {
            prueba_rapida:{
                fecha:{required},
                prueba_id:{required},
                trimestre:{required},
                resultado:{required},
                casa_comercial:{required},
                lote:{required},
                fecha_vencimiento:{required},
                tipo_prueba:{required}
            }
        }
    },
    methods:{
        async getPruebas(){
            const response = await pruebasRapidasService.index();
            this.pruebas=response.data;
        },
        clear(){
            this.prueba_rapida.prueba_id='';
            this.prueba_rapida.casa_comercial='';
            this.prueba_rapida.lote='';
            this.prueba_rapida.fecha_vencimiento='';
            this.prueba_rapida.tipo_prueba='';
            this.prueba_rapida.trimestre='';
            this.prueba_rapida.resultado='';
            this.prueba_rapida.observaciones='';
        },
        async save(){
            try {
                if(isEmpty(this.usuario)){
                    Swal.fire({
                        text: "Debe seleccionar un usuario antes de continuar",
                        icon: "info",
                        confirmButtonText: "Ok",
                    });
                    return ;
                }

                if(isEmpty(this.eps)){
                    Swal.fire({
                        text: "Verifique la eps del usuario",
                        icon: "info",
                        confirmButtonText: "Ok",
                    });
                    return ;
                }

                this.$v.$touch();

                if (this.$v.$invalid) return;


                let data={
                    id_usuario:this.usuario.id,
                    ...this.prueba_rapida,
                    eps_id:this.eps.id
                }

                this.LoaderSpinnerShow();
                await pruebasRapidasUsuarioService.store(data);
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: "success",
                    title: "Datos guardados con exito",
                });

                this.clear();
            } catch (error) {
                
                console.log(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: "error",
                    title: "Ha ocurrido un error al procesar la solicitud",
                });
            }
        }
    },
    async created(){
        await this.getPruebas();
        const currenDate=dayjs().format("YYYY-MM-DD");
        this.prueba_rapida.fecha=currenDate;
    }
}
</script>
<style scoped>
    .required::after{
        content: '*';
        margin-left: 2px;
        color: red;
    }
</style>


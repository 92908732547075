import Service from "./Service";
const baseurl = '/api/pruebas-rapidas-usuario';
export default {
    index(params={}) {
        return Service.get(`${baseurl}`,{
            params: params
        });
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    async showByIdUser(search = '',pagination = {},id) {
       
        const hasAditionalParams = !!pagination['params'];

        let aditionalParams = {};

        if(hasAditionalParams){
            for(const param in pagination['params']){
                if(
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ){
                    aditionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s:search} : {}),
            ...(pagination['per_page'] ? {per_page : pagination['per_page']} : {}),
            ...(pagination['page'] ? {page : pagination['page']} : {}),
            ...(hasAditionalParams ? {...aditionalParams} : {}),
        };

        return Service.get(`${baseurl}/show-by-id-usuario/${id}?`, {
            params : {
                ...params
            }
        });
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
    print(id){
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/print/${id}`
    }
}